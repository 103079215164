/* eslint-disable no-extend-native */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import moment from 'moment'
import superjson from 'superjson'

import { useRouter } from 'next/router'
import { Suspense, useEffect, useState, useMemo } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { Global } from '@emotion/react'
import { config } from '@fortawesome/fontawesome-svg-core'
import { ToastContainer } from 'react-toastify'
import { DevTools } from 'jotai-devtools'

import { useLocalStorage } from '@sergeimeza/foundation-react'

import { QueryClient, QueryClientProvider } from 'react-query'

import { EuiContext, EuiErrorBoundary } from '@elastic/eui'

import { trpc } from '../services/trpc'
import { auth } from '../services/firebase'

import { AuthProvider } from '../context/AuthContext'
import { TranslationProvider } from '../context/TranslationContext'

import { MyAppWithTRPC } from '../hoc/trpc-hoc'

import { useIsMounted } from '../hooks/useIsMounted'
import { LemonUmamiScript } from '../analytics/umami'

import { Loading } from '../components/common/loading-simple'
import { Theme } from '../components/theme'
import { Chrome } from '../components/chrome'
import { ActivityDetection } from '../components/common/activity-detection'
import { globalStyles } from '../styles/global.styles'

// !! WARN !!
// Normalizes css. Therefore import order is important
import '../../assets/globals.scss'

import '../../assets/scss/nextjs-argon-dashboard-pro.scss'
import '../../assets/vendor/nucleo/css/nucleo.css'

import '@fortawesome/fontawesome-svg-core/styles.css'
import '@sendbird/uikit-react/dist/index.css'

import 'react-notification-alert/dist/animate.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'select2/dist/css/select2.min.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@fullcalendar/common/main.min.css'
import '@fullcalendar/daygrid/main.min.css'

import 'react-toastify/dist/ReactToastify.css'
import 'react-month-picker-input/dist/react-month-picker-input.css'

import '@elastic/eui/dist/eui_theme_light.css'
import '@elastic/charts/dist/theme_only_light.css'

import 'moment/locale/ja'
import Script from 'next/script'

config.autoAddCss = false

moment.locale('ja')

const mappings = {
  ja: {
    'euiTablePagination.rowsPerPage': 'ページごとの表示件数',
    'euiPrettyDuration.now': '現在',
    'euiDatePopoverContent.absoluteTabLabel': '日付',
    'euiDatePopoverContent.relativeTabLabel': '範囲',
    'euiDatePopoverContent.nowTabLabel': '現在',
    'euiTimeOptions.secondsAgo': '秒前',
    'euiTimeOptions.minutesAgo': '分前',
    'euiTimeOptions.hoursAgo': '時間前',
    'euiTimeOptions.daysAgo': '日前',
    'euiTimeOptions.weeksAgo': '週間前',
    'euiTimeOptions.monthsAgo': 'ヶ月前',
    'euiTimeOptions.yearsAgo': '年間前',
    'euiDatePopoverContent.startDateLabel': '開始日',
    'euiTimeOptions.roundToSecond': '秒始めに合わせる',
    'euiTimeOptions.roundToMinute': '分始めに合わせる',
    'euiTimeOptions.roundToHour': '時間始めに合わせる',
    'euiTimeOptions.roundToDay': '日始めに合わせる',
    'euiTimeOptions.roundToWeek': '週間始めに合わせる',
    'euiTimeOptions.roundToMonth': '月始めに合わせる',
    'euiTimeOptions.roundToYear': '年始めに合わせる',
    'euiQuickSelect.quickSelectTitle': 'クイックセレクト',
    'euiCommonlyUsedTimeRanges.legend': 'よく使うもの',
    'euiTimeOptions.today': '本日',
    'euiTimeOptions.thisWeek': '今週',
    'euiTimeOptions.thisMonth': '今月',
    'euiTimeOptions.thisYear': '今年',
    'euiTimeOptions.yesterday': '昨日',
    'euiTimeOptions.weekToDate': '今週〜本日',
    'euiTimeOptions.monthToDate': '今月〜本日',
    'euiTimeOptions.yearToDate': '今年〜本日',
    'euiQuickSelect.applyButton': '適応',
    'euiDatePopoverContent.nowTabButtonStart':
      '開始日と開始時間を現在に設定する',
    'euiDatePopoverContent.nowTabContent':
      '開始時間を「現在」に設定すると、毎回のページの更新に開始時間が更新時の時間に設定されます。',
    'euiPrettyDuration.lastDurationSeconds': ({ duration }) =>
      `${duration}秒前`,
    'euiPrettyDuration.lastDurationMinutes': ({ duration }) =>
      `${duration}分前`,
    'euiPrettyDuration.lastDurationHours': ({ duration }) =>
      `${duration}時間前`,
    'euiPrettyDuration.lastDurationDays': ({ duration }) => `${duration}日前`,
    'euiPrettyDuration.lastDurationWeeks': ({ duration }) =>
      `${duration}週間前`,
    'euiPrettyDuration.lastDurationMonths': ({ duration }) =>
      `${duration}ヶ月前`,
    'euiPrettyDuration.lastDurationYears': ({ duration }) =>
      `${duration}年間前`,
    'euiPrettyDuration.nextDurationSeconds': ({ duration }) =>
      `${duration}秒前`,
    'euiPrettyDuration.nextDurationMinutes': ({ duration }) =>
      `${duration}分後`,
    'euiPrettyDuration.nextDurationHours': ({ duration }) =>
      `${duration}時間後`,
    'euiPrettyDuration.nextDurationDays': ({ duration }) => `${duration}日後`,
    'euiPrettyDuration.nextDurationWeeks': ({ duration }) =>
      `${duration}週間後`,
    'euiPrettyDuration.nextDurationMonths': ({ duration }) =>
      `${duration}ヶ月後`,
    'euiPrettyDuration.nextDurationYears': ({ duration }) =>
      `${duration}年間後`,
  },
  en: {},
}

const BigIntPrototype = BigInt.prototype as any
BigIntPrototype.toJSON = function toJSON() {
  return this.toString()
}

const MyAppComponent = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    auth.useDeviceLanguage()
  }, [])

  const [token, setToken] = useLocalStorage<string | null>('auth_token', null)

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 5000,
          },
        },
      }),
  )
  const router = useRouter()

  const trpcClient = useMemo(
    () =>
      trpc.createClient({
        url: `${process.env.NEXT_PUBLIC_LEMON_API}/trpc`,

        transformer: superjson,

        // optional

        headers() {
          return {
            authorization: token ?? undefined,
          }
        },
      }),
    [token],
  )
  const Layout = (Component as any).layout || (({ children }) => children)
  const i18n = {
    mapping: router.locale === 'ja' ? mappings.ja : mappings.en,
  }
  return (
    <>
      <DevTools />
      <Head>
        {/* You can override this in other pages */}
        <title>Lemon Square - CMS</title>
      </Head>
      <Script id='ms_clarity'>
        {`(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "gue6xmj1ca");`}
      </Script>
      <Global styles={globalStyles} />
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<Loading />}>
            <AuthProvider token={token} setToken={setToken}>
              <TranslationProvider>
                <Theme>
                  <Chrome>
                    <EuiErrorBoundary>
                      {/* Load script on the client not the server!!! */}
                      <LemonUmamiScript />
                      {/* <ActivityDetection> */}
                      <Layout>
                        <ToastContainer />
                        <EuiContext i18n={i18n}>
                          <Component {...pageProps} />
                        </EuiContext>
                      </Layout>
                      {/* </ActivityDetection> */}
                    </EuiErrorBoundary>
                  </Chrome>
                </Theme>
              </TranslationProvider>
            </AuthProvider>
          </Suspense>
        </QueryClientProvider>
      </trpc.Provider>
    </>
  )
}

const MyApp = MyAppWithTRPC(MyAppComponent)

const AppWrapper = props => {
  const isMounted = useIsMounted()

  return isMounted && <MyApp {...props} />
}

export default AppWrapper
